<template>
    <vs-row id="diensten" justify="center">
        <vs-col :w="wModule()" class="module box-shadow">
            <vs-row justify="center">
                <vs-col w="12"><div class="line"></div></vs-col>
                <vs-col w="12" class="space">
                    <div class="background">
                        <h1 class="title">Diensten | Advies</h1>
                    </div>
                </vs-col>

                <vs-col w="12">
                    <img class="landscape image" src="@/assets/images/advies.jpg" alt="">
                </vs-col>

                <vs-col align="left" :w="w0()">
                    <h4>
                        Als specialist in industriële oplossingen biedt MD Electro een integrale aanpak op het gebied van procesdesign, werktuigbouwkundige installaties, industriële 
                        automatisering, veiligheid, paneelbouw en –montage. MD Electro werkt al vele jaren voor kleine en (middel)grote opdrachtgevers in de proces- en maakindustrie, 
                        machine- en waterbouw, waarvoor het zowel bestaande productieprocessen, productie-uitbreiding als complete nieuwbouw verzorgt. Door productieprocessen slim 
                        te automatiseren wordt de afhankelijkheid van mankracht verminderd, wat een positieve invloed heeft op het bedrijfsresultaat van opdrachtgevers.<br><br>
                        Met MD Electro's one-stop-shopping aanbod krijgen opdrachtgevers in de procesindustrie, waterbranche en maakindustrie integrale industriële oplossingen voor ieder 
                        vraagstuk. MD Electro verzorgt daarbij nieuwbouw, upgrades en migraties van technische installaties, volgens een aanpak waarbij efficiëntie, veiligheid en duurzaamheid 
                        voorop staan. <br><br>
                        Dankzij uitgebreide kennis van productieprocessen, energiebeheer, besturingstechnieken en ICT-infrastructuren zorgen de betrokken, proactieve en ondernemende 
                        teamleden voor maximale toegevoegde waarde. Daarmee stelt MD Electro zijn opdrachtgevers in staat om nu en in de toekomst duurzaam, innovatief, efficiënt en betrouwbaar 
                        te blijven produceren.
                    </h4>
                </vs-col>

                <vs-col w="12"><div class="line"></div></vs-col>
            </vs-row>
        </vs-col>

        <DienstenTabbie/>
    </vs-row>
</template>

<script>
import DienstenTabbie from '../components/DienstenTabbie.vue'

    export default {
        data() {
            return {

            }
        },
        components: {
            DienstenTabbie
        },

        methods: {
        wModule() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
        w0() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
    },
    }
</script>

<style lang="sass" scoped>
.landscape
    display: block 
    width: 80%
    height: 350px
    object-fit: cover
    margin: auto

.contact-btn
    margin: auto
    border-radius: 10px

.module
    margin-bottom: 30px


</style>