<template>
    <vs-col :w="wModule()" class="module box-shadow">
        <vs-row justify="center">
            <vs-col w="12"><div class="line"></div></vs-col>
            <vs-col w="12" class="space">
                <div class="background">
                    <h3 class="title">De diensten van MD Electro</h3>
                </div>
            </vs-col>


            <vs-col :w="w0()">
                <h4 style="font-weight:900"> 
                <router-link class="nav-link" :to="{ name: 'advies' }">Advies</router-link> | <router-link class="nav-link" :to="{ name: 'inspecties' }">Inspecties</router-link> | <router-link class="nav-link" :to="{ name: 'electrotechniek' }">Electrotechniek</router-link> | Data-installaties | 
                    Inbraakbeveiliging | Brandmeldsystemen | Service & onderhoud
                </h4>
            </vs-col>
            <vs-col w="12"><div class="line"></div></vs-col>
        </vs-row>
    </vs-col>
</template>

<script>
    export default {
        data() {
            return {

            }
        },

        methods: {
        wModule() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
        w0() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
    },
    }
</script>

<style lang="sass" scoped>
.title
    font-weight: 900
        
.landscape
    display: block 
    width: 80%
    object-fit: cover
    margin: auto

.contact-btn
    margin: auto
    border-radius: 10px

.module
    margin-bottom: 30px

.nav-link
    color: #2c3e50
    font-weight: 900
    // margin: 0px 10px 0px 10px
    // text-decoration: underline
    // cursor: pointer
</style>
